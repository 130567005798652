canvas {
  opacity:.9;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 1;
}

#app {
  position: absolute;
  z-index: 1000;
  opacity:.8;
  color: #cccccc;
  width: 100%;
  height:100%;
}

header {
  background-color: #333 !important;
}